@charset "UTF-8";
:root {
  --client-main-font-family:"Montserrat", sans-serif;
  --client-alt-font-family:"Lato", sans-serif;
  --client-color-default:#111111;
  --client-color-principal:#00843D;
  --client-color-secundaria:#1B1B1B;
  --client-color-alternativa:#E4F1E9;
  --client-color-extra:#9BCEAE;
  --client-color-titulos:#111111;
  --client-color-texto:#3D3935;
  --client-color-btn-border:#111111;
  --client-color-btn-border-hover:#111111;
  --client-color-btn-backg:#E4F1E9;
  --client-color-btn-backg-hover:#00843D;
  --client-color-btn-texto:#00843D;
  --client-color-btn-texto-hover:#E4F1E9;
  --client-color-background-modulo:#F5F5F4;
  --client-color-backg-modulo-destaque:#F7C7B6;
  --client-color-background:#ffffff;
  --client-color-menu-link:#111111;
  --client-color-menu-titulo:#111111;
  --client-color-btn-backg-secundario:#00843D;
  --client-color-btn-texto-secundario:#E4F1E9;
  --client-color-btn-backg-hover-secundario:#2C9C62;
  --client-color-btn-texto-hover-secundario:#ffffff;
  --client-body-backgroud-color: rgba(248, 249, 253, 0.42);
  --loader-color: #00a65a;
  --backgroud-flatpickr: transparent;
}

[data-color=Aprovado] {
  color: green;
}

[data-color=Aprovado] .label-status {
  background-color: #00a65a;
  color: white;
}

[data-color=Reprovado] {
  color: red;
}

[data-color=Reprovado] .label-status {
  background-color: #dd4b39;
  color: white;
}

[data-color="Aguarda Revisão"] {
  color: orange;
}

[data-color="Aguarda Revisão"] .label-status {
  background-color: #d99821;
  color: white;
}

[data-layout=client] .navbar {
  background-color: var(--client-color-background) !important;
  color: var(--client-color-principal) !important;
}
[data-layout=client] .navbar .nav > li > a {
  color: var(--client-color-principal) !important;
}
[data-layout=client] .navbar .nav > li > a:hover {
  background: rgba(0, 0, 0, 0.1) !important;
  transition: all 400ms ease-in-out !important;
}
[data-layout=client] header > nav > .sidebar-toggle {
  color: var(--client-color-principal) !important;
}
[data-layout=client] header > nav > .sidebar-toggle:hover {
  background: var(--client-color-extra) !important;
  color: var(--client-color-btn-texto-hover) !important;
  transition: all 400ms ease-in-out !important;
  color: var(--client-color-principal) !important;
  background: rgba(0, 0, 0, 0.1) !important;
}
[data-layout=client] .content-wrapper {
  background-color: var(--client-body-backgroud-color) !important;
}
[data-layout=client] .wrapper {
  background-color: var(--client-body-backgroud-color) !important;
}
[data-layout=client] .main-header > .logo {
  background-color: var(--client-color-background) !important;
  color: var(--client-color-btn-texto) !important;
}
[data-layout=client] .main-header > .logo:hover {
  background-color: unset;
}
[data-layout=client] .main-header > .logo img {
  width: 100%;
}
[data-layout=client] .sidebar-menu > li.active > a {
  color: var(--client-color-principal) !important;
}
[data-layout=client] .sidebar-menu > li:hover > a {
  color: var(--client-color-principal) !important;
  transition: all 400ms ease-in-out !important;
}
[data-layout=client] .sidebar-menu .treeview-menu > li > a:hover {
  color: var(--client-color-principal) !important;
  transition: all 400ms ease-in-out !important;
}
[data-layout=client] .sidebar-menu > li.menu-open > a {
  color: var(--client-color-principal) !important;
}
[data-layout=client] .user-panel > .info > p > a {
  color: var(--client-color-principal) !important;
}
[data-layout=client] .main-footer {
  background-color: var(--client-body-backgroud-color) !important;
}
[data-layout=client] .box {
  border: 1px solid #ddd;
}
[data-layout=client] .main-sidebar {
  box-shadow: inset 0 1px 7px 1px rgba(0, 0, 0, 0.05);
}
[data-layout=client] .main-header .navbar {
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.05);
}
[data-layout=client] .text-green {
  color: var(--client-color-btn-texto) !important;
}
@media (max-width: 768px) {
  [data-layout=client] .main-header > .logo img {
    width: 50% !important;
  }
}

.avatar-xs {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-sm {
  height: 2.25rem;
  width: 2.25rem;
}

.avatar-md {
  height: 3.5rem;
  width: 3.5rem;
}

.avatar-lg {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-xl {
  height: 6rem;
  width: 6rem;
}

.avatar-xxl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
  -webkit-box-align: center;
  align-items: center;
  color: #ffffff;
  display: -webkit-box;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
}

.avatar-group {
  padding-left: 12px;
}

.avatar-group .avatar-group-item {
  margin: 0 0 10px -12px;
  display: inline-block;
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.max-w-10em {
  max-width: 10em;
}

.font-10 {
  font-size: 10px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-19 {
  font-size: 19px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.strike {
  text-decoration: line-through;
}

.bg-soft-danger {
  background-color: rgba(241, 85, 108, 0.25);
}

.badge {
  color: #fff;
}

.text-danger {
  color: #f1556c !important;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.bg-soft-success {
  background-color: rgba(26, 188, 156, 0.25);
}

.text-success {
  color: #1abc9c !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  justify-content: flex-end !important;
}

.w-15-percent {
  width: 15%;
}

.table-title-center > thead > tr > th {
  text-align: center;
  vertical-align: middle;
}

.pre-wrap {
  white-space: pre-wrap;
}

.has-error {
  border-color: #f1556c !important;
}

.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}